var getComputedStyle = document.defaultView.getComputedStyle
var ctx = '@@infiniteScroll'

function throttle(fn, delay) {
  var timer,
      now,
      last = 0

  function exec() {
    fn()
    last = now
  }
  return function () {
    if (timer) {
      clearTimeout(timer)
    }
    now = Date.now()
    var diff = now - last
    if (diff >= delay) {
      exec()
    } else {
      timer = setTimeout(fn, diff)
    }
  }
}

// function getElementTop(el) {
//   if (el === window) {
//     return getScrollTop(window)
//   }
//   return el.getBoundingClientRect().top + getScrollTop(el)
// }

function getScrollTop(el) {
  if (el === window) {
    return Math.max(window.pageYOffset || 0, document.documentElement.scrollTop)
  }
  return el.scrollTop
}

function getVisibleHeight(el) {
  if (el === window) {
    return document.documentElement.clientHeight
  }
  return el.clientHeight
}

// function isVisible(el) {
//   return el && el.style.display !== 'none'
// }

function getScrollEventTarget(el) {
  var currentNode = el
  const isWindow = currentNode.getAttribute("infinite-scroll-window") === 'true'
  // 通过infinite-scroll-window="true"明确指定了使用window作为滚动目标，直接返回window，避免重排
  if (isWindow) return window

  while (currentNode) {
    if (
      currentNode &&
      currentNode.tagName !== 'HTML' &&
      currentNode.tagName !== 'BODY' &&
      currentNode.nodeType == 1
    ) {
      var overflowY = getComputedStyle(currentNode).overflowY
      if (overflowY === 'scroll' || overflowY === 'auto') {
        return currentNode
      }
    }
    currentNode = currentNode.parentNode
  }
  return window
}

// function getScrollHeight(el) {
//   if (el == window) {
//     return document.documentElement.scrollHeight
//   }
//   return el.scrollHeight
// }

var doBind = function doBind() {
  var context = this
  var scrollEventTarget = getScrollEventTarget(context.el)
  context.scrollEventTarget = scrollEventTarget
  context.scrollListener = throttle(doCheck.bind(context), 200)
  scrollEventTarget.addEventListener('scroll', context.scrollListener)

  context.vm.$on('hook:activated', function () {
    scrollEventTarget.addEventListener('scroll', context.scrollListener)
  })
  context.vm.$on('hook:deactivated', function () {
    scrollEventTarget.removeEventListener('scroll', context.scrollListener)
  })

  var disabledState = context.el.getAttribute('infinite-scroll-disabled')
  var distance = context.el.getAttribute('infinite-scroll-distance') || 300
  context.distance = distance
  if (disabledState) {
    context.disabledState = disabledState
    context.vm.$watch(disabledState, function (value) {
      context.disabled = value
      if (!value) {
        doCheck.call(context)
      }
    })
  }
  context.disabled = false
}

// const bailRE = /[^\w.$]/
function parseNoDataPath(path) {
  const segments = (path && path.split('.')) || []
  return function (obj) {
    for (let i = 0; i < segments.length; i++) {
      if (!obj) return
      obj = obj[segments[i]] || obj?._setupProxy?.[segments[i]]
    }
    return obj
  }
}

function getElementOffsetTop(elem, container) {
  var offsetTop = 0
  while (elem.offsetParent && (container === window || container.contains(elem.offsetParent))) {
    offsetTop += elem.offsetTop
    elem = elem.offsetParent
  }
  return offsetTop
}

var doCheck = function doCheck() {
  var context = this

  if (context.vm[context.disabledState]) return false

  var distance = Number(context.distance)
  var scrollEventTarget = getScrollEventTarget(context.el)
  var viewportScrollTop = getScrollTop(scrollEventTarget)
  var nodata = parseNoDataPath(context.el.getAttribute('infinite-scroll-nodata'))(context.vm)

  var shouldTrigger = false

  var elementOffsetTop =
    getElementOffsetTop(context.el, scrollEventTarget) + context.el.clientHeight

  shouldTrigger =
    viewportScrollTop &&
    elementOffsetTop &&
    viewportScrollTop + getVisibleHeight(scrollEventTarget) + distance >= elementOffsetTop
  if (shouldTrigger && !nodata) {
    context.expression()
  }
}

var directive = {
  bind: function bind(el, binding, vnode) {
    el[ctx] = {
      el: el,
      vm: vnode.context,
      expression: binding.value
    }

    function tryBind() {
      doBind.call(el[ctx])
    }
    el[ctx].vm.$nextTick(tryBind)
  },
  unbind(el) {
    if (el[ctx] && el[ctx].scrollEventTarget) {
      el[ctx].scrollEventTarget.removeEventListener('scroll', el[ctx].scrollListener)
    }
  }
}

export default directive
