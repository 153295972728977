import directive from './directive'

var infiniteScroll = {
  install: (Vue) => {
    Vue.directive('infinite-scroll', directive)
  }
}

if(window.Vue) {
  Vue.use(infiniteScroll)
}

export default infiniteScroll
