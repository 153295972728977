import './style.less'
export const TextResizePlugin = {
  install(Vue) {
    const overflowClass = 'text-ellipsis'
    const directiveName = 'text-resize'

    const emitErrorIfFlexDisplay = (el) => {
      if (process.env.NODE_ENV !== 'production' && getComputedStyle(el).display === 'flex') {
        console.error(
          `[${directiveName} error]: The "${directiveName}" directive should not be used on flex container elements. Wrap the text in a span tag instead, and make sure that the element's width is not infinitely expandable. Set width: 100% to constrain it.`
        )
      }
    }

    const resizeText = (el, binding) => {
      const { GB_cssRight } = gbCommonInfo
      const minimumFontSize = binding.value?.minSize || 10

      emitErrorIfFlexDisplay(el)
      el.classList.add('scui-text__resize')

      Vue.nextTick(() => {
        const offsetWidth = el.offsetWidth
        const scrollWidth = el.scrollWidth

        if (scrollWidth > offsetWidth) {
          const scaleRatio = offsetWidth / scrollWidth
          const currentFontSize = parseFloat(getComputedStyle(el).fontSize)

          if (currentFontSize * scaleRatio < minimumFontSize) {
            el.classList.add(overflowClass)
            el.style.fontSize = `${minimumFontSize}px`
            el.style.transform = 'none'
          } else {
            el.classList.remove(overflowClass)
            el.style.transform = `scale(${scaleRatio})`
            el.style.transformOrigin = GB_cssRight ? 'right' : 'left'
          }
        } else {
          el.style.transform = 'none'
          el.classList.remove(overflowClass)
        }
      })
    }

    Vue.directive(directiveName, {
      bind(el, binding) {
        resizeText(el, binding)
      },
      update(el, binding) {
        resizeText(el, binding)
      }
    })
  }
}
