import Vue from 'vue'
import { Toast, Loading } from '@shein/sui-mobile'
import { debounce } from '@shein/common-function'

Vue.prototype.$toast = Toast
Vue.component(Loading.name, Loading)

let compInstance = null

export const quickAdd = {
  open(config) {
    return this.instanceOpen(config)
  },
  instanceOpen: debounce({
    func: (config) => {

      if (compInstance) {
        compInstance.open(config)
        return compInstance
      }
      
      return new Promise((resolve) => {
        import(/* webpackChunkName: "quick_add" */ './QuickAdd.vue').then((components) => {
          const quickAdd = components.default
          const vm = new Vue({
            render (h) {
              return h(quickAdd)
            }
          }).$mount()
          document.body.appendChild(vm.$el)
          const comp = vm.$children[0]
          comp.open(config)
          compInstance = comp
          resolve(compInstance)
        })
      })
    },
    wait: 200,
    options: {
      leading: true,
    }
  }),
  changeStatus: (key, val) => { // 改变快速加车内部信息 慎用 TODO 后期要优化
    if (compInstance) {
      compInstance.changeStatus(key, val)
    }
  }
}
