import { TextResizePlugin } from '../scui'
import 'public/src/services/eventCenterV2/'
import { useSaDirective } from '@shein-aidc/basis-sa-event-center'
export { tap, expose, useSaDirective } from '@shein-aidc/basis-sa-event-center'

export default {
  install() {
    if (!Vue) return
    Vue.use(TextResizePlugin)
    useSaDirective(Vue)
  }
}
